import { FC, useContext, useEffect, useMemo, useRef, useState } from 'react'
import classNames from 'classnames'
import { Bet, CashoutMessage, RoundResults, StartRoundMessage } from '../../../types/State'
import s from './Chart.module.css'
import winImg from '../../../assets/win.png'
import rocketImg from '../../../assets/rocket.png'
import { checkCurrencyCode } from '../../../utils/checkCurrencyCode'
import { convertCurrency } from '../../../utils/convertCurrency'
import InitialStateContext from '../../../context/InitialStateContext'
import { useTranslation } from '../../../hooks/useTranslation'

const PADDING = 70
const PADDING_RIGHT = 160
const Y_MIN = 1

export type ChartDataType = Array<[number, number]>

const Chart: FC<IProps> = ({
                               data,
                               isCrashed,
                               roundStartedData,
                               betTime,
                               history,
                               win,
                               currencyCode,
                               cashouts,
                           }) => {
    const { t } = useTranslation()
    const [initState] = useContext(InitialStateContext)

    const canvasRef = useRef<HTMLCanvasElement | null>(null)
    const [ctx, setCtx] = useState<CanvasRenderingContext2D | null>(null)

    const [dpiWidth, setDpiWidth] = useState(0)
    const [dpiHeight, setDpiHeight] = useState(0)
    const [viewHeight, setViewHeight] = useState(0)
    const [viewWidth, setViewWidth] = useState(0)

    useEffect(() => {
        if (!roundStartedData || ctx) return

        initChart(canvasRef)
    }, [canvasRef, roundStartedData])

    const requestRef = useRef<number | undefined>()
    const prevCoordsIndex = useRef(1)

    const animate = (time: number) => {
        if (!ctx || !data) return

        if (data.length - prevCoordsIndex.current > 5) {
            prevCoordsIndex.current = data.length - 1
        }

        if (data.length === 1) {
            prevCoordsIndex.current = 1
        }

        const drawData = data.slice(0, Math.floor(prevCoordsIndex.current))
        const {xMax, yMax} = computeBoundaries(drawData)
        draw(ctx, drawData, xMax, yMax)
        prevCoordsIndex.current += 1

        requestRef.current = requestAnimationFrame(animate as unknown as FrameRequestCallback)
    }

    const crashCounter = useRef(1)

    useEffect(() => {
        requestRef.current = requestAnimationFrame(animate)
        return () => cancelAnimationFrame(requestRef.current ?? 0)
    }, [ctx, data, isCrashed])

    function initChart(canvas: typeof canvasRef) {
        if (!canvas.current) return

        const ctx = canvas.current.getContext('2d') as CanvasRenderingContext2D

        const height = ctx.canvas.offsetHeight
        const width = ctx.canvas.offsetWidth

        ctx.canvas.width = width * 2
        ctx.canvas.height = height * 2

        setDpiWidth(width * 2)
        setDpiHeight(height * 2)
        setViewHeight(height * 2 - PADDING * 2)
        setViewWidth(width * 2 - PADDING - PADDING_RIGHT)

        setCtx(ctx)
    }

    function draw(
        ctx: CanvasRenderingContext2D,
        data: Array<[number, number]>,
        realXMax: number,
        realYMax: number
    ) {
        clear(ctx)
        const rocket = new Image()
        rocket.src = rocketImg

        const xMax = realXMax < 15 ? 15 : realXMax
        const yMax = realYMax < 5 ? 5 : realYMax

        const xRatio = viewWidth / xMax
        const yRatio = viewHeight / (yMax - Y_MIN)

        // draw axes
        drawYAxis(ctx, yMax, Y_MIN)
        drawXAxis(ctx, xMax)

        // draw flight
        ctx.beginPath()
        ctx.strokeStyle = !isCrashed ? 'white' : '#494545'
        ctx.lineWidth = 8

        for (const [x, y] of data) {
            ctx.lineTo(x * xRatio + PADDING, dpiHeight - PADDING - (y - Y_MIN) * yRatio)
        }
        ctx.stroke()
        ctx.closePath()

        // draw shadow
        ctx.beginPath()
        const gradient = ctx.createLinearGradient(
            PADDING,
            dpiHeight - PADDING - (data[data.length - 1][1] - Y_MIN) * yRatio,
            PADDING,
            dpiHeight - PADDING,
        )

        gradient.addColorStop(0, !isCrashed ? '#ff9c00' : '#494545')
        gradient.addColorStop(1, 'rgba(24,25,27,0)')
        ctx.strokeStyle = 'rgba(24,25,27,0'
        ctx.fillStyle = gradient

        for (const [x, y] of data) {
            ctx.lineTo(x * xRatio + PADDING, dpiHeight - PADDING - (y - Y_MIN) * yRatio)
        }

        ctx.lineTo(
            data[data.length - 1][0] * xRatio + PADDING,
            dpiHeight - PADDING,
        )
        ctx.fill()
        ctx.closePath()

        // draw rocket
        ctx.beginPath()
        let rocketPosition = {
            x: data[data.length - 1][0] * xRatio + PADDING - 10,
            y: dpiHeight -PADDING - (data[data.length - 1][1] - Y_MIN) * yRatio - 44,
        }

        if (!isCrashed) {
            crashCounter.current = 1
            ctx.drawImage(rocket, rocketPosition.x, rocketPosition.y)
        } else {
            ctx.drawImage(
                rocket,
                rocketPosition.x + crashCounter.current,
                rocketPosition.y + crashCounter.current * 7,
            )
            crashCounter.current++
        }

        ctx.closePath()

        // total time
        ctx.beginPath()
        ctx.fillStyle = '#3c3a3a'
        ctx.fillText(`Total ${Math.floor(data[data.length - 1][0])}s`, dpiWidth - 110, dpiHeight - 35)
        ctx.closePath()
    }

    function drawYAxis(ctx: CanvasRenderingContext2D, yMax: number, yMin: number) {
        const rowsCount = 4
        const step = viewHeight / rowsCount
        const textStep = (yMax - yMin) / rowsCount

        ctx.beginPath()
        ctx.strokeStyle = '#3c3a3a'
        ctx.lineWidth = 2
        ctx.font = 'normal 24px Inter, sans-serif'
        ctx.fillStyle = '#3c3a3a'

        for (let i = 0; i <= rowsCount; i++) {
            const y = step * i
            const text = yMax - textStep * i
            ctx.fillText(text.toFixed(1) + 'x', 5, y + PADDING + 7)
            ctx.moveTo(PADDING, y + PADDING)
            ctx.lineTo(dpiWidth, y + PADDING)
        }
        ctx.stroke()
        ctx.closePath()
    }

    function drawXAxis(ctx: CanvasRenderingContext2D, xMax: number) {
        const columnsCount = 8
        const step = viewWidth / columnsCount
        const textStep = xMax / columnsCount

        ctx.beginPath()
        ctx.strokeStyle = '#3c3a3a'
        ctx.lineWidth = 2
        ctx.font = 'normal 24px Inter, sans-serif'
        ctx.fillStyle = '#3c3a3a'

        ctx.moveTo(PADDING, 0)
        ctx.lineTo(PADDING, dpiHeight - PADDING)
        ctx.stroke()

        for (let i = 1; i <= columnsCount; i++) {
            const x = step * i
            const text = textStep * i
            ctx.fillText(Math.round(text) + 's', x + PADDING - 10, dpiHeight - 35)
        }
        ctx.closePath()
    }

    function computeBoundaries(data: ChartDataType) {
        return {
            xMax: data[data.length - 1][0],
            yMax: data[data.length - 1][1],
        }
    }

    function clear(ctx: CanvasRenderingContext2D) {
        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height)
    }

    const startingDuration = useMemo(
        () => roundStartedData ? roundStartedData.data.startedRound.duration : 1,
        [roundStartedData]
    )
    const startingProgress = useMemo(
        () => 100 / startingDuration * (betTime - 1),
        [startingDuration, betTime]
    ) // percents

    return (
        <div className={s.wrapper}>
            <div className={s.head}>
                <div className={s.results}>
                    {history
                        ?.filter(round => !!round.roundData)
                        .slice(0, 6)
                        .reverse()
                        .map(({roundId, roundData}) => (
                            <button
                                key={roundId}
                                className={classNames(
                                    s.results__item,
                                    roundData && roundData > 2.5 && s.results__item_green,
                                )}
                            >
                                {roundData}x
                            </button>
                        ))
                    }
                </div>
            </div>
            <div className={s.chart}>
                {roundStartedData ? (
                    <>
                        <div className={s.info}>
                            <p
                                className={classNames(s.odd, isCrashed && s.odd_crashed)}
                            >
                                {betTime
                                    ? `${betTime.toFixed(1)}s`
                                    : `${data[data.length - 1][1]?.toFixed(2)}×`
                                }
                            </p>
                            {!!betTime && (
                                <div className={classNames(s.message, s.starting)}>
                                    <div
                                        className={s.starting__progressbar}
                                        style={{width: startingProgress + '%'}}
                                    />
                                    <span>{t('STARTINGIN')}</span>
                                </div>
                            )}
                            {isCrashed && (
                                <div className={classNames(s.message, s.crashed)}>
                                    <span>{t('CRASHED')}</span>
                                </div>
                            )}
                        </div>
                        <div className={classNames(s.win, win && s.win_active)}>
                            <p>{t('WON')} <span>{win} {checkCurrencyCode(currencyCode)}</span></p>
                            <img src={winImg} alt="coins"/>
                        </div>
                        <div className={s.cashouts}>
                            {cashouts
                                ?.slice(0, 5)
                                .map(item => (
                                <div key={item.id} className={s.cashouts__item}>
                                    {item.userId}
                                    <span>
                                        {convertCurrency(
                                            item.winCoinAmount,
                                            item.winAmount,
                                            item.currencyCode ?? '',
                                            initState,
                                        )}
                                    </span>
                                </div>
                            ))}
                        </div>
                        <canvas ref={canvasRef} className={s.canvas}/>
                    </>
                ) : (
                    <p>{t('ROUNDINPROGRESS')}</p>
                )}
            </div>
        </div>
    )
}

export default Chart

interface IProps {
    data: ChartDataType
    roundStartedData: StartRoundMessage | null
    betTime: number
    isCrashed: boolean
    win: number
    history: RoundResults[]
    currencyCode: string | null
    cashouts: Array<CashoutMessage | Bet>
}