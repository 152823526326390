import React, { FC } from 'react'
import Table from '../../../Table/Table'
import { PlayerStatsItem } from '../../../../api/player'
import { svgIcons } from '../../../../assets/svgIcons'
import {useTranslation} from "../../../../hooks/useTranslation";

const History: FC<IProps> = ({stats}) => {
    const { t } = useTranslation()

    return (
        <Table>
            <thead>
            <tr>
                <td>{t('ROUNDID')}</td>
                <td>{t('BET')}</td>
                <td>{t('GAMERESULT')}</td>
                <td>{t('PAYOUT')}</td>
            </tr>
            </thead>
            <tbody>
            {stats
                ?.filter(round => !!round.roundData)
                .map(round => (
                    <tr key={round.roundId}>
                        <td>
                            <div className="flex items-center justify-center gap-2">
                                <span>{svgIcons.footer.security}</span>
                                <span>{round.roundId}</span>
                            </div>
                        </td>
                        <td>{round.amount} {round.currencyCode}</td>
                        <td>{round.roundData}x</td>
                        <td>{round.winAmount} {round.currencyCode}</td>
                    </tr>
                ))}
            </tbody>
        </Table>
    )
}

export default History

interface IProps {
    stats: PlayerStatsItem[]
}