import { FC, useEffect, useState } from 'react'
import { svgIcons } from '../../../assets/svgIcons'
import s from './ProvablyFairnessPopup.module.css'
import Input from '../../Input/Input'
import Button from '../../Button/Button'
import { RoundResults } from '../../../types/State'
import Api from '../../../api'
import {useTranslation} from "../../../hooks/useTranslation";

const ProvablyFairnessModal: FC<IProps> = ({ lastRound }) => {
    const { t } = useTranslation()

    if (!lastRound) return <></>

    const [round, setRound] = useState(lastRound)
    const [nextHash, setNextHash] = useState('')

    useEffect(() => {

    }, []);

    const calculate = async () => {
        const { result } = await Api().result.getResult(
            round.resultKey ?? '',
            round?.resultSalt ?? '',
        )
        setNextHash(result)
    }

    return (
        <>
            <div className={s.head}>
                <p className={s.title}>
                    {svgIcons.fairness}
                    {t('TITLE')}
                </p>
                <p className="text-center">
                    {t('LASTROUNDID')}: {round.roundId}
                </p>
                <p className={s.odd}>{round.roundData}×</p>
                <div className={s.grid}>
                    <Input
                        value={round.resultKey ?? ''}
                        onChange={() => {}}
                        label={t('KEY')}
                        inputBackground="#534F4F"
                        canCopy
                        readOnly
                    />
                    <Input
                        value={round.resultSalt ?? ''}
                        onChange={() => {}}
                        label={t('SALT')}
                        inputBackground="#534F4F"
                        canCopy
                        readOnly
                    />
                    <Input
                        value={round.resultHash}
                        onChange={() => {}}
                        label={t('HASHCODE')}
                        className={s.full}
                        inputBackground="#534F4F"
                        canCopy
                        readOnly
                    />
                </div>
                <Button onClick={() => calculate()} className={s.calculateBtn}>
                    {t('CALCULATE')}
                </Button>
            </div>

            {nextHash && (
                <div className={s.content}>
                    <p className="text-center">
                        {t('NEXTROUND')}
                    </p>
                    <Input
                        value={nextHash}
                        onChange={setNextHash}
                        label={t('HASHCODE')}
                        className={s.nextHash}
                        inputBackground="#534F4F"
                        canCopy
                    />
                    {/*<p>Hash Code ?</p>*/}
                </div>
            )}
        </>
    )
}

export default ProvablyFairnessModal

interface IProps {
    lastRound: RoundResults | undefined
}