import React, {FC, useContext, useEffect, useMemo, useState} from 'react'
import s from './Control.module.css'
import Button from '../../Button/Button'
import Input from '../../Input/Input'
import Checkbox from '../../Checkbox/Checkbox'
import InitialStateContext from '../../../context/InitialStateContext'
import BalanceContext from '../../../context/BalanceContext'
import { BetMessage, UpdateStateMessage } from '../../../types/State'
import { useSounds } from '../../../utils/useSounds'
import { checkCurrencyCode } from '../../../utils/checkCurrencyCode'
import ErrorContext from '../../../context/ErrorContext'
import {useTranslation} from "../../../hooks/useTranslation";

const Control: FC<IProps> = ({
                                 roundId,
                                 amount,
                                 setAmount,
                                 targetMultiplier,
                                 setTargetMultiplier,
                                 isAutobet,
                                 setIsAutobet,
                                 numberOfRounds,
                                 setNumberOfRounds,
                                 state,
                                 onBet,
                                 onCashout,
                                 betTime,
                                 currentBet,
                                 win,
                                 nextBet,
                                 setNextBet,
}) => {
    const { t } = useTranslation()
    const [, setErr] = useContext(ErrorContext)

    const [initialState] = useContext(InitialStateContext)
    const [balance] = useContext(BalanceContext)

    const { autoplaySound } = useSounds()

    const changeValue = (
        name: 'amount' | 'targetMultiplier' | 'numberOfRounds',
        prevValue: string,
        newValue: string | ((prev: string) => string)
    ) => {
        if (typeof newValue !== 'string') {
            newValue = newValue(prevValue)
        }

        // validate min target multiplier
        if (name === 'targetMultiplier' && newValue !== '' && +newValue < 1) {
            return
        }

        // validate number of rounds
        if (name === 'numberOfRounds' && ((newValue !== '' && +newValue < 1) || (+newValue > 10000))) {
            return
        }

        switch (name) {
            case 'amount' :
                setAmount(newValue)
                localStorage.setItem(process.env.REACT_APP_AMOUNT_KEY as string, newValue)
                break
            case 'targetMultiplier':
                setTargetMultiplier(newValue)
                localStorage.setItem(process.env.REACT_APP_TARGET_MULTIPLIER_KEY as string, newValue)
                break
            case 'numberOfRounds':
                setNumberOfRounds(newValue)
                break
        }
    }

    /* Bet amount */
    const divideAmountValidation = (amount: string): string => {
        if (initialState.limits && +amount / 2 >= initialState.limits.minBet) {
            return (+amount / 2).toFixed(initialState?.currencyPrecision).toString()
        }

        return amount
    }

    const multiplyAmountValidation = (amount: string): string => {
        if (initialState.limits && +amount * 2 <= initialState.limits.maxBet) {
            return (+amount * 2).toFixed(initialState?.currencyPrecision).toString()
        }

        return amount
    }

    const maxAmountValidation = (amount: string) => {
        if (initialState.limits) {
            return initialState?.limits?.maxBet.toFixed(initialState?.currencyPrecision)
        } else {
            return amount
        }
    }

    const amountActions = [
        {
            text: '½',
            action: () => changeValue('amount', amount, divideAmountValidation(amount)),
        },
        {
            text: '2×',
            action: () => changeValue('amount', amount, multiplyAmountValidation(amount)),
        },
        {
            text: t('MAX'),
            action: () => changeValue('amount', amount, maxAmountValidation(amount)),
        }
    ]
    /* ----------- */

    const [winChance, setWinChance] = useState('0')

    /* Number Of Rounds */
    const numberOfRoundsActions = [
        { text: '20', action: () => setNumberOfRounds('20') },
        { text: '50', action: () => setNumberOfRounds('50') },
        { text: '100', action: () => setNumberOfRounds('100') },
    ]
    /* ----------- */

    useEffect(() => {
        if (!initialState) return

        const amount = localStorage.getItem(process.env.REACT_APP_AMOUNT_KEY as string)
        const multiplier = localStorage.getItem(process.env.REACT_APP_TARGET_MULTIPLIER_KEY as string)

        const defaultAmount = initialState?.limits?.minBet
            .toFixed(initialState?.currencyPrecision)
            .toString() ?? '0'

        setAmount(
            amount ?? defaultAmount
        )

        if (multiplier) {
            setTargetMultiplier(multiplier)
        }

    }, [initialState])

    useEffect(() => {
        setWinChance((98 / +targetMultiplier).toFixed(2) + '%')
    }, [targetMultiplier])

    const cashoutValue = useMemo(
        () => {
            const value = ((currentBet?.amount ?? 0) * (state?.data?.updateStateData?.odd ?? 1))
                .toFixed(initialState?.currencyPrecision)
            return value + ' ' + checkCurrencyCode(initialState?.currencyCode)
        },
        [currentBet, state, initialState],
    )

    // autoplay sound
    useEffect(() => {
        if (isAutobet) {
            autoplaySound()
        }
    }, [isAutobet])

    const toggleIsAutobet = (checked: boolean) => {
        if (checked && +numberOfRounds < 1) {
            return
        }

        if (+amount > balance) {
            setErr(t('NOBALANCE'))
            return
        }

        setIsAutobet(checked)
    }

    const doNextBet = () => {
        if (+amount > balance) {
            setErr(t('NOBALANCE'))
            return
        }

        setNextBet({ amount: +amount, targetMultiplier: +targetMultiplier })
    }

    return (
        <div className={s.control}>
            {!currentBet && !!betTime && !nextBet && (
                <Button
                    onClick={() => onBet(+amount, +targetMultiplier, roundId as number)}
                    disabled={!betTime || isAutobet}
                    clickSoundExcept
                >
                    {t('BET')}
                </Button>
            )}
            {!currentBet && !betTime && (
                <Button
                    onClick={doNextBet}
                    disabled={isAutobet || !!nextBet}
                    clickSoundExcept
                >
                    {t('NEXTBET')}
                </Button>
            )}
            {currentBet && (
                <Button
                    onClick={() => onCashout()}
                    disabled={!!betTime || !!win || !state}
                    isSecondary
                    clickSoundExcept
                >
                    <span className="flex flex-col">
                        <span>{cashoutValue}</span>
                        <span>{t('CASHOUT')}</span>
                    </span>
                </Button>
            )}

            <Input
                value={amount}
                onChange={value =>
                    changeValue('amount', amount, value)
                }
                label={t('BETAMOUNT')}
                actions={amountActions}
                currency={checkCurrencyCode(initialState?.currencyCode)}
                min={initialState?.limits?.minBet}
                max={initialState?.limits?.maxBet}
                precision={initialState?.currencyPrecision}
                autoReplace={[
                    [/[^.\d]+/g,""],
                    [/^([^\.]*\.)|\./g, '$1'],
                ]}
            />

            <div className={s.grid}>
                <Input
                    value={targetMultiplier}
                    onChange={value =>
                        changeValue('targetMultiplier', targetMultiplier, value)
                    }
                    label={t('TARGETMULTIPLIER')}
                    controls
                    step={0.01}
                    min={1.01}
                    precision={2}
                    autoReplace={[
                        [/[^.\d]+/g,""],
                        [/^([^\.]*\.)|\./g, '$1'],
                    ]}
                    maxDecimalsPlace={6}
                />
                <Input
                    value={winChance}
                    readOnly
                    onChange={() => {
                    }}
                    label={t('WINCHANCE')}
                />
            </div>

            <div className={s.autobet}>
                <Input
                    value={numberOfRounds}
                    onChange={value =>
                        changeValue('numberOfRounds', numberOfRounds, value)
                }
                    label={t('NUMROUNDS')}
                    actions={numberOfRoundsActions}
                    max={10000}
                    autoReplace={[[/[^\d]/ig, '']]}
                    readOnly={isAutobet}
                />
                <Checkbox
                    checked={isAutobet}
                    onChange={toggleIsAutobet}
                    className={s.checkbox}
                    clickSoundExcept
                >
                    {t('AUTOBET')}
                </Checkbox>
            </div>
        </div>
    )
}

export default Control

interface IProps {
    roundId: number
    amount: string
    setAmount: (value: ((prev: string) => string) | string) => void
    targetMultiplier: string
    setTargetMultiplier: (value: ((prev: string) => string) | string) => void
    isAutobet: boolean
    setIsAutobet: (value: boolean) => void
    numberOfRounds: string
    setNumberOfRounds: (value: ((prev: string) => string) | string) => void
    state: UpdateStateMessage | null
    onBet: (amount: number, targetMultiplier: number, roundId: number) => void
    onCashout: () => void
    betTime: number
    currentBet: BetMessage | null
    win: number
    nextBet: INextBet | null
    setNextBet: (value: INextBet | null) => void
}

export interface INextBet {
    amount: number
    targetMultiplier: number
}
