import React, { FC, useState } from 'react'
import Switch from '../../Switch/Switch'
import LiveBets from './Tabs/LiveBets/LiveBets'
import History from './Tabs/History'
import HighRollers from './Tabs/HighRollers'
import {Bet, BetMessage, CashoutMessage, CurrentBet} from '../../../types/State'
import { PlayerStatsItem } from '../../../api/player'
import {useTranslation} from "../../../hooks/useTranslation";

const Statistic: FC<IProps> = ({liveBets, stats, gameName, liveCashouts}) => {
    const { t } = useTranslation()
    const tabs = [
        t('LIVEBETS'),
        t('HISTORY'),
        t('HIGHROLLERS'),
    ]
    const [selectedTab, setSelectedTab] = useState<string>(tabs[0])

    return (
        <div style={{ padding: '0 10px 10px' }}>
            <Switch
                className="mb-4"
                tabs={tabs}
                name="tabs"
                value={selectedTab}
                onChange={setSelectedTab}
            />

            {selectedTab === 'Live Bets' && <LiveBets liveBets={liveBets} liveCashouts={liveCashouts} />}
            {selectedTab === 'History' && <History stats={stats}/>}
            {selectedTab === 'High Rollers' && <HighRollers gameName={gameName} />}
        </div>
    )
}

export default Statistic

interface IProps {
    liveBets: Array<CurrentBet | BetMessage>
    stats: PlayerStatsItem[]
    gameName: string | null
    liveCashouts: Array<CashoutMessage | Bet>
}