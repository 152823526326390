import React, {FC, ReactNode, useContext, useMemo} from 'react'
import s from './Layout.module.css'
import { svgIcons } from '../../assets/svgIcons'
import logo from '../../assets/logo.svg'
import BalanceContext from '../../context/BalanceContext'
import InitialStateContext from '../../context/InitialStateContext'
import { checkCurrencyCode } from '../../utils/checkCurrencyCode'
import { useSearchParams } from 'react-router-dom'
import Footer from '../Game/Footer/Footer'

const Layout: FC<IProps> = ({children}) => {
    const [initialState] = useContext(InitialStateContext)
    const [balance] = useContext(BalanceContext)

    const [params] = useSearchParams()
    const exitUrl = useMemo(() => params.get('ExitUrl'), [params])

    return (
        <>
            {!!Object.keys(initialState).length && (
                <div className={s.head}>
                    <a href={exitUrl ?? ''} target="_parent">{svgIcons.arrows.left}</a>
                    <img src={logo} alt="Crash"/>
                    <div className={s.balance}>
                        <span>{checkCurrencyCode(initialState?.currencyCode)}</span>
                        <span>{balance.toFixed(initialState?.currencyPrecision)}</span>
                        {/*<Button onClick={() => deposit()} className={s.btn}>*/}
                        {/*    Deposit*/}
                        {/*</Button>*/}
                    </div>
                </div>
            )}

            <div className={s.content}>
                {children}
            </div>

            {!!Object.keys(initialState).length && <Footer/>}
        </>
    )
}

export default Layout

interface IProps {
    children: ReactNode
}