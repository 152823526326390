import { checkCurrencyCode } from './checkCurrencyCode'
import {InitialState} from "../types/State";

export const convertCurrency = (
    coin: number,
    originValue: number,
    currencyCode: string,
    initState: InitialState | null,
): string => {
    if (!initState) return ''

    let result: number = 0

    if (currencyCode === initState.currencyCode) {
        result = originValue
    } else {
        result = coin * initState.currencyRate
    }

    return `${result.toFixed(initState.currencyPrecision)} ${checkCurrencyCode(initState.currencyCode)}`
}