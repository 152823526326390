const codes: Record<string, string> = {
    "USD": "$",
    "GBP": "£",
    "EUR": "€",
    "JPY": "¥",
    "CNY": "¥",
    "AUD": "$",
    "CAD": "$",
    "CHF": "fr.",
    "SEK": "kr",
    "NOK": "kr",
    "DKK": "kr",
    "INR": "₹",
    "RUB": "₽",
    "MXN": "$",
    "BRL": "R$",
    "ZAR": "R",
    "KRW": "₩",
    "TRY": "₺",
    "ILS": "₪",
    "AMD": "֏",
    "HKD": "$",
    "SGD": "$",
    "NZD": "$",
    "THB": "฿",
    "PLN": "zł",
    "PHP": "₱",
    "TWD": "$",
    "VND": "₫",
}

export const checkCurrencyCode = (currencyCode: string | null): string => {
    if (currencyCode) {
        return codes[currencyCode] !== undefined ? codes[currencyCode] : currencyCode
    } else {
        return ''
    }
}