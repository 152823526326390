import React, { ReactNode } from 'react'
import s from './Table.module.css'

const Table = ({ children}: { children: ReactNode }) => {
  return (
      <table className={s.table}>
        {children}
      </table>
  )
}

export default Table