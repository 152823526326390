import { useContext } from 'react'
import s from './Footer.module.css'
import { svgIcons } from '../../../assets/svgIcons'
import classNames from 'classnames'
import GameConfigContext from '../../../context/GameConfigContext'
import PopupsOpenContext, { IPopups } from '../../../context/PopupsContext'

const Footer = () => {
    const [, setPopupsOpen] = useContext(PopupsOpenContext)

    const [{ isMute }, changeGameConfig] = useContext(GameConfigContext)

    const changeMute = (value: boolean) => {
        localStorage.setItem(process.env.REACT_APP_MUTE_KEY ?? '', JSON.stringify(value))
        changeGameConfig('isMute', value)
    }

    const openPopup = (popup: keyof IPopups) => {
        setPopupsOpen(prev => ({
            ...prev,
            [popup]: true,
        }))
    }

    return (
        <div className={s.footer}>
            <button
                className={classNames(s.btn, !isMute && s.btn_active)}
                onClick={() => changeMute(!isMute)}
            >
                {svgIcons.footer.sound}
            </button>
            <button className={s.btn} onClick={() => openPopup('rules')}>
                {svgIcons.footer.info}
            </button>
            <button
                className={classNames(s.btn, s.btn_active)}
                onClick={() => openPopup('provablyFairness')}
            >
                {svgIcons.footer.security}
            </button>
        </div>
    )
}

export default Footer