export const svgIcons = {
    inputControls: {
        arrowUp: (
            <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M9.70711 5.70711C9.31658 6.09763 8.68342 6.09763 8.29289 5.70711L5 2.41421L1.70711 5.70711C1.31658 6.09763 0.683417 6.09763 0.292894 5.70711C-0.0976312 5.31658 -0.0976312 4.68342 0.292894 4.29289L4.29289 0.292893C4.68342 -0.0976311 5.31658 -0.0976311 5.70711 0.292893L9.70711 4.29289C10.0976 4.68342 10.0976 5.31658 9.70711 5.70711Z"
                      fill="white"/>
            </svg>
        ),
        arrowDown: (
            <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M0.292893 0.292893C0.683417 -0.097631 1.31658 -0.097631 1.70711 0.292893L5 3.58579L8.29289 0.292893C8.68342 -0.0976311 9.31658 -0.0976311 9.70711 0.292893C10.0976 0.683417 10.0976 1.31658 9.70711 1.70711L5.70711 5.70711C5.31658 6.09763 4.68342 6.09763 4.29289 5.70711L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683418 0.292893 0.292893Z"
                      fill="white"/>
            </svg>
        ),
        copy: (
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M4 6C4 4.89543 4.89543 4 6 4H12C13.1046 4 14 4.89543 14 6V12C14 13.1046 13.1046 14 12 14H6C4.89543 14 4 13.1046 4 12V6Z"
                    fill="white"/>
                <path
                    d="M2 0C0.895431 0 0 0.89543 0 2V8C0 9.10457 0.89543 10 2 10L2 4C2 2.89543 2.89543 2 4 2H10C10 0.895431 9.10457 0 8 0H2Z"
                    fill="white"/>
            </svg>
        ),
    },
    arrows: {
        left: (
            <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M7.70711 0.292893C8.09763 0.683418 8.09763 1.31658 7.70711 1.70711L2.41421 7L7.70711 12.2929C8.09763 12.6834 8.09763 13.3166 7.70711 13.7071C7.31658 14.0976 6.68342 14.0976 6.29289 13.7071L0.292893 7.70711C-0.0976308 7.31658 -0.0976308 6.68342 0.292893 6.29289L6.29289 0.292893C6.68342 -0.0976312 7.31658 -0.0976311 7.70711 0.292893Z"
                      fill="white"/>
            </svg>
        ),
        up: (
            <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M13.7071 7.70711C13.3166 8.09763 12.6834 8.09763 12.2929 7.70711L7 2.41421L1.70711 7.70711C1.31658 8.09763 0.683417 8.09763 0.292893 7.70711C-0.0976314 7.31658 -0.0976313 6.68342 0.292894 6.29289L6.29289 0.292893C6.68342 -0.0976311 7.31658 -0.0976311 7.70711 0.292893L13.7071 6.29289C14.0976 6.68342 14.0976 7.31658 13.7071 7.70711Z"
                      fill="white"/>
            </svg>
        ),
        down: (
            <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M0.292893 0.292893C0.683417 -0.097631 1.31658 -0.097631 1.70711 0.292893L7 5.58579L12.2929 0.292893C12.6834 -0.0976311 13.3166 -0.0976311 13.7071 0.292893C14.0976 0.683417 14.0976 1.31658 13.7071 1.70711L7.70711 7.70711C7.31658 8.09763 6.68342 8.09763 6.29289 7.70711L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683418 0.292893 0.292893Z"
                      fill="white"/>
            </svg>
        ),
    },
    footer: {
        sound: (
            <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M8.38268 1.07615C8.75636 1.23093 9 1.59557 9 2.00003V14C9 14.4045 8.75636 14.7691 8.38268 14.9239C8.00901 15.0787 7.57889 14.9931 7.29289 14.7071L3.58579 11H1C0.447715 11 0 10.5523 0 10V6.00003C0 5.44774 0.447715 5.00003 1 5.00003H3.58579L7.29289 1.29292C7.57889 1.00692 8.00901 0.921369 8.38268 1.07615Z"
                      fill="#525151"/>
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M13.6568 0.92888C14.0474 0.538355 14.6805 0.538355 15.0711 0.92888C16.8796 2.73743 18 5.2388 18 7.99995C18 10.7611 16.8796 13.2625 15.0711 15.071C14.6805 15.4615 14.0474 15.4615 13.6568 15.071C13.2663 14.6805 13.2663 14.0473 13.6568 13.6568C15.1057 12.208 16 10.2094 16 7.99995C16 5.79053 15.1057 3.7919 13.6568 2.34309C13.2663 1.95257 13.2663 1.3194 13.6568 0.92888ZM10.8284 3.75731C11.2189 3.36678 11.8521 3.36678 12.2426 3.75731C12.7685 4.28319 13.1976 4.90687 13.5003 5.59958C13.822 6.33592 14 7.14847 14 7.99995C14 9.65653 13.3273 11.1579 12.2426 12.2426C11.8521 12.6331 11.2189 12.6331 10.8284 12.2426C10.4379 11.8521 10.4379 11.2189 10.8284 10.8284C11.5534 10.1034 12 9.10479 12 7.99995C12 7.42922 11.8811 6.8889 11.6676 6.40032C11.4663 5.93958 11.1802 5.52327 10.8284 5.17152C10.4379 4.781 10.4379 4.14783 10.8284 3.75731Z"
                      fill="#525151"/>
            </svg>
        ),
        boost: (
            <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M8.30064 0.0462125C8.71692 0.177433 9 0.56348 9 0.99995V5.99995L13 5.99995C13.3729 5.99995 13.7148 6.20741 13.887 6.53814C14.0592 6.86887 14.0331 7.26794 13.8192 7.57341L6.81924 17.5734C6.56894 17.931 6.11564 18.0849 5.69936 17.9537C5.28309 17.8225 5 17.4364 5 17L5 12H1C0.627127 12 0.285222 11.7925 0.113024 11.4618C-0.0591735 11.131 -0.0330591 10.732 0.180771 10.4265L7.18077 0.426488C7.43107 0.0689178 7.88436 -0.0850076 8.30064 0.0462125Z"
                      fill="#525151"/>
            </svg>
        ),
        users: (
            <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M12 3C12 4.65685 10.6569 6 9 6C7.34315 6 6 4.65685 6 3C6 1.34315 7.34315 0 9 0C10.6569 0 12 1.34315 12 3Z"
                    fill="#525151"/>
                <path
                    d="M17 5C17 6.10457 16.1046 7 15 7C13.8954 7 13 6.10457 13 5C13 3.89543 13.8954 3 15 3C16.1046 3 17 3.89543 17 5Z"
                    fill="#525151"/>
                <path d="M13 12C13 9.79086 11.2091 8 9 8C6.79086 8 5 9.79086 5 12V16H13V12Z" fill="#525151"/>
                <path
                    d="M5 5C5 6.10457 4.10457 7 3 7C1.89543 7 1 6.10457 1 5C1 3.89543 1.89543 3 3 3C4.10457 3 5 3.89543 5 5Z"
                    fill="#525151"/>
                <path
                    d="M15 16V12C15 10.9459 14.7282 9.9552 14.2507 9.09432C14.4902 9.03275 14.7413 9 15 9C16.6569 9 18 10.3431 18 12V16H15Z"
                    fill="#525151"/>
                <path
                    d="M3.74926 9.09432C3.27185 9.9552 3 10.9459 3 12V16H0V12C0 10.3431 1.34315 9 3 9C3.25871 9 3.50977 9.03275 3.74926 9.09432Z"
                    fill="#525151"/>
            </svg>
        ),
        keyboard: (
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g opacity="0.2">
                    <path
                        d="M1 6C1 4.89543 2.00736 4 3.25 4H16.75C17.9926 4 19 4.89543 19 6C19 9.79881 19 10.9536 19 14C19 15.1046 17.9926 16 16.75 16H3.25C2.00736 16 1 15.1046 1 14C1 10.9536 1 9.79881 1 6Z"
                        fill="white"/>
                    <circle cx="4" cy="7" r="1" fill="#272525"/>
                    <circle cx="4" cy="13" r="1" fill="#272525"/>
                    <circle cx="7" cy="7" r="1" fill="#272525"/>
                    <circle cx="10" cy="7" r="1" fill="#272525"/>
                    <circle cx="10" cy="10" r="1" fill="#272525"/>
                    <circle cx="13" cy="7" r="1" fill="#272525"/>
                    <circle cx="16" cy="7" r="1" fill="#272525"/>
                    <circle cx="16" cy="13" r="1" fill="#272525"/>
                    <rect x="6" y="12" width="8" height="2" rx="1" fill="#272525"/>
                    <rect x="3" y="9" width="5" height="2" rx="1" fill="#272525"/>
                    <rect x="12" y="9" width="5" height="2" rx="1" fill="#272525"/>
                </g>
            </svg>
        ),
        info: (
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM9 12C9 12.5523 8.55229 13 8 13C7.44772 13 7 12.5523 7 12C7 11.4477 7.44772 11 8 11C8.55229 11 9 11.4477 9 12ZM8 3C7.44772 3 7 3.44772 7 4V8C7 8.55228 7.44772 9 8 9C8.55229 9 9 8.55228 9 8V4C9 3.44772 8.55229 3 8 3Z"
                      fill="#525151"/>
            </svg>
        ),
        security: (
            <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M0.166107 3.99891C3.17437 3.95809 5.91528 2.81033 8 0.944458C10.0847 2.81033 12.8256 3.95809 15.8339 3.99891C15.9431 4.64968 16 5.31821 16 6.00003C16 11.2249 12.6608 15.6698 8 17.3172C3.33923 15.6698 0 11.2249 0 6.00003C0 5.31821 0.0568637 4.64968 0.166107 3.99891ZM11.7071 7.70711C12.0976 7.31658 12.0976 6.68342 11.7071 6.29289C11.3166 5.90237 10.6834 5.90237 10.2929 6.29289L7 9.58579L5.70711 8.29289C5.31658 7.90237 4.68342 7.90237 4.29289 8.29289C3.90237 8.68342 3.90237 9.31658 4.29289 9.70711L6.29289 11.7071C6.68342 12.0976 7.31658 12.0976 7.70711 11.7071L11.7071 7.70711Z"
                      fill="#525151"/>
            </svg>
        ),
    },
    modal: {
        close: (
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L6 4.58579L10.2929 0.292893C10.6834 -0.0976311 11.3166 -0.0976311 11.7071 0.292893C12.0976 0.683417 12.0976 1.31658 11.7071 1.70711L7.41421 6L11.7071 10.2929C12.0976 10.6834 12.0976 11.3166 11.7071 11.7071C11.3166 12.0976 10.6834 12.0976 10.2929 11.7071L6 7.41421L1.70711 11.7071C1.31658 12.0976 0.683417 12.0976 0.292893 11.7071C-0.0976311 11.3166 -0.0976311 10.6834 0.292893 10.2929L4.58579 6L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893Z"
                      fill="white"/>
            </svg>
        ),
    },
    fairness: (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M7.99998 0C8.55226 0 8.99998 0.447715 8.99998 1V2.32297L12.9544 3.90474L14.5528 3.10557C15.0467 2.85858 15.6474 3.05881 15.8944 3.55279C16.1414 4.04676 15.9412 4.64744 15.4472 4.89443L14.214 5.51101L15.9522 10.9307C16.0727 11.3065 15.961 11.718 15.6669 11.9812C14.9599 12.614 14.0238 13 13 13C11.9761 13 11.0401 12.614 10.3331 11.9812C10.039 11.718 9.92723 11.3065 10.0477 10.9307L11.7631 5.58227L8.99998 4.47703V14H11C11.5523 14 12 14.4477 12 15C12 15.5523 11.5523 16 11 16H4.99997C4.44769 16 3.99997 15.5523 3.99997 15C3.99997 14.4477 4.44769 14 4.99997 14H6.99997V4.47703L4.23689 5.58227L5.9522 10.9307C6.07272 11.3065 5.96096 11.718 5.66689 11.9812C4.95988 12.614 4.02381 13 2.99997 13C1.97614 13 1.04007 12.614 0.333061 11.9812C0.0389932 11.718 -0.0727749 11.3065 0.0477483 10.9307L1.78592 5.51101L0.552761 4.89443C0.0587828 4.64744 -0.141442 4.04676 0.105548 3.55279C0.352537 3.05881 0.95321 2.85858 1.44719 3.10557L3.04553 3.90474L6.99997 2.32297V1C6.99997 0.447715 7.44769 0 7.99998 0ZM2.99997 8.27448L2.18174 10.8258C2.43132 10.9378 2.708 11 2.99997 11C3.29194 11 3.56863 10.9378 3.81821 10.8258L2.99997 8.27448ZM13 8.27448L12.1817 10.8258C12.4313 10.9378 12.708 11 13 11C13.2919 11 13.5686 10.9378 13.8182 10.8258L13 8.27448Z"
                  fill="white"/>
        </svg>
    ),
}