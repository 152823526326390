import { FC, useContext, useEffect, useState } from 'react'
import Api from '../../../../api'
import { Topwin } from '../../../../api/game'
import Table from '../../../Table/Table'
import { convertCurrency } from '../../../../utils/convertCurrency'
import InitialStateContext from '../../../../context/InitialStateContext'
import {useTranslation} from "../../../../hooks/useTranslation";

const HighRollers: FC<IProps> = ({ gameName }) => {
    const { t } = useTranslation()
    const [initState] = useContext(InitialStateContext)
    const [topwins, setTopwins] = useState<Topwin[]>([])

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (!gameName) return

                const { result } = await Api().game.getTopwins(gameName)
                setTopwins(result)
            } catch (e) {
                //
            }
        }
        fetchData()
    }, [])

    return (
        <Table>
            <thead>
            <tr>
                <td>{t('USERID')}</td>
                <td>{t('BETAMOUNT')}</td>
                <td>{t('PAYOUT')}</td>
            </tr>
            </thead>
            <tbody>
            {topwins?.map(item => (
                <tr key={item.id}>
                    <td>{item.userId}</td>
                    <td>
                        {convertCurrency(
                            item.coinAmount,
                            item.amount,
                            item.currencyCode,
                            initState,
                        )}
                    </td>
                    <td>
                        {convertCurrency(
                            item.winCoinAmount,
                            item.winAmount,
                            item.currencyCode,
                            initState,
                        )}
                    </td>
                </tr>
            ))}
            </tbody>
        </Table>
    )
}

export default HighRollers

interface IProps {
    gameName: string | null
}