import { createContext } from 'react'
import { Translations } from '../hooks/useTranslation'
const initGameConfig: IGameConfig = {
    isMute: false,
    translations: null
}
const GameConfigContext = createContext<GameConfigContextType>([initGameConfig, () => {}])
export default GameConfigContext
type GameConfigContextType = [
    IGameConfig,
    (param: keyof IGameConfig, value: boolean | Translations) => void,
]
export interface IGameConfig {
    isMute: boolean
    translations: Translations | null
}