import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import s from './Game.module.css'
import Chart, { ChartDataType } from './Chart/Chart'
import Control, { INextBet } from './Control/Control'
import { useSearchParams } from 'react-router-dom'
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr'
import {
    Bet,
    BetMessage,
    BetTimeFinishedMessage, CashoutMessage, CurrentBet, FinishedRoundMessage,
    InitialState, RoundResults,
    StartRoundMessage,
    UpdateStateMessage,
} from '../../types/State'
import InitialStateContext from '../../context/InitialStateContext'
import BalanceContext from '../../context/BalanceContext'
import GameConfigContext from '../../context/GameConfigContext'
import Statistic from './Statistic/Statistic'
import Api from '../../api'
import Popup from '../Popup/Popup'
import ProvablyFairnessModal from './ProvablyFairnessPopup/ProvablyFairnessPopup'
import { PlayerStatsItem } from '../../api/player'
import { useSounds } from '../../utils/useSounds'
import PopupsOpenContext, { IPopups } from '../../context/PopupsContext'
import RulesPopup from './RulesPopup/RulesPopup'
import ErrorContext from '../../context/ErrorContext'
import yo from '../../assets/yo.png'
import {useTranslation} from "../../hooks/useTranslation";

const Game = () => {
    const { t } = useTranslation()
    const [, setErr] = useContext(ErrorContext)

    const { betSound, cashoutSound, crashSound, winSound} = useSounds()

    const [params] = useSearchParams()
    const partnerId = params.get('PartnerId')
    const userIdRef = useRef(params.get('UserId'))
    const gameName = params.get('GameName')
    const oneTimeToken = params.get('OneTimeToken')
    const mode = params.get('Mode')

    const [chartData, setChartData] = useState<ChartDataType>([[0, 1]])

    const [connection, setConnection] = useState<HubConnection | null>(null)

    const [liveBets, setLiveBets] = useState<Array<CurrentBet | BetMessage>>([])
    const [history, setHistory] = useState<RoundResults[]>([])
    const [stats, setStats] = useState<PlayerStatsItem[]>([])

    const [liveCashouts, setLiveCashouts] = useState<Array<CashoutMessage | Bet>>([])

    const [balance, setBalance] = useContext(BalanceContext)
    const [initialState, setInitialState] = useContext(InitialStateContext)
    const [{ isMute }] = useContext(GameConfigContext)
    const [popupOpen, setPopupOpen] = useContext(PopupsOpenContext)

    const openPopup = useCallback(
        (popup: keyof IPopups, value: boolean) => {
            setPopupOpen(prev => ({
                ...prev,
                [popup]: value,
            }))
        },
        [popupOpen]
    )

    // Control
    const [amount, setAmount] = useState<string>('0')
    const [targetMultiplier, setTargetMultiplier] = useState('100.00')
    const [isAutobet, setIsAutobet] = useState(false)
    const isAutobetRef = useRef(false)

    useEffect(() => {
        isAutobetRef.current = isAutobet
    }, [isAutobet])

    const [numberOfRounds, setNumberOfRounds] = useState('10')

    const [state, setState] = useState<UpdateStateMessage | null>(null)
    const [roundStartedData, setRoundStartedData] = useState<StartRoundMessage | null>(null)

    const betTimeTimerRef = useRef<NodeJS.Timer>()
    const [betTime, setBetTime] = useState(0)
    const [isCrashed, setIsCrashed] = useState(false)
    const [win, setWin] = useState(0)

    const [currentBet, setCurrentBet] = useState<BetMessage | null>(null)
    const [nextBet, setNextBet] = useState<INextBet | null>(null)

    const handleCashout = (data: CashoutMessage) => {
        setLiveBets(prev =>
            prev.map(liveBet => {
                if (data.id === liveBet.id) {
                    return {
                        ...liveBet,
                        winAmount: data.winAmount,
                    }
                } else {
                    return liveBet
                }
            })
        )

        setLiveCashouts(prev => [data, ...prev])

        if (userIdRef.current && +userIdRef.current === data.userId) {
            setWin(data.winAmount)
            setCurrentBet(null)
            winSound()
        }
    }

    useEffect(() => {
        if (!win) return

        setBalance(prev => prev + win)
        const timerId = setTimeout(() => setWin(0), 3000)
        return () => clearTimeout(timerId)
    }, [win])

    const onBet = async (amount: number, targetMultiplier: number, roundId: number) => {
        if (!connection) return

        if (initialState.limits) {
            if (amount > initialState.limits.maxBet || amount < initialState.limits.minBet) {
                return
            }
        }

        if (amount > balance) {
            setErr(t('NOBALANCE'))
            return
        }

        if (targetMultiplier < 1.01) {
            return
        }

        betSound()

        setBalance(prev => prev - amount)

        const data = {
            roundId,
            betData: {
                autoCashoutOdd: targetMultiplier,
            },
            amount,
        }

        try {
            connection.on('BalanceUpdate', (data: number) => setBalance(data))
            await connection.invoke('Bet', data)
        } catch (e) {
            // todo err
        } finally {
            connection.off('BalanceUpdate')
        }
    }

    const onCashout = async () => {
        cashoutSound()
        if (!connection) return

        const data = {
            betId: currentBet?.id,
            roundId: roundStartedData?.data.startedRound.roundId,
        }

        try {
            connection.on('CashoutResult', handleCashout)
            await connection.invoke('Cashout', data)
        } catch (e) {
            // todo err
        } finally {
            connection.off('CashoutResult')
        }
    }

    const updateBalance = async () => {
        let demoUserId: string | null = null

        if (mode === 'demo') {
            demoUserId = userIdRef.current
        }

        try {
            const { result } = await Api().player.getBalance(demoUserId)
            if (typeof result === 'number') {
                setBalance(result)
            }
        } catch (e) {
            //
        }
    }

    const updateStatistics = async () => {
        try {
            const { result: { items }} = await Api().player.getStatistics(
                gameName as string,
                0,
                20
            )
            setStats(items)
        } catch (e) {
            //
        }
    }

    const autoBet = async (roundId: number) => {
        if (!roundStartedData) return

        await onBet(+amount, +targetMultiplier, roundId)

        setNumberOfRounds(prev => {
            if (+prev === 1) {
                setIsAutobet(false)
            }
            return (+prev - 1).toString()
        })
    }

    const handleInitState = (data: InitialState) => {
        setBalance(data.balance)
        setInitialState(data)
        setLiveBets(data.currentBets ?? [])
        setHistory(data.roundResults ?? [])
        updateStatistics()

        if (!userIdRef.current) {
            userIdRef.current = data.userId.toString()
        }
    }

    const handleRoundStart = (data: StartRoundMessage) => {
        updateBalance()

        setIsCrashed(false)

        if (nextBet) {
            const { amount, targetMultiplier } = nextBet
            onBet(amount, targetMultiplier, data.data.startedRound.roundId)
        }

        setBetTime(data.data.startedRound.duration)
        const timer = setInterval(
            () => setBetTime(prev => prev > 0 ? prev - 0.1 : 0),
            100
        )
        betTimeTimerRef.current = timer

        setWin(0)

        setState(null)
        setChartData([[0, 1]]) // init chart

        setLiveBets([])
        setHistory(data?.data?.roundResults)
        updateStatistics()

        setLiveCashouts([])

        setRoundStartedData(data)

        if (isAutobetRef.current) {
            autoBet(data.data.startedRound.roundId)
        }
    }

    const handleBetTimeFinished = (data: BetTimeFinishedMessage) => {
        // console.log(data)
        setBetTime(0)
        setNextBet(null)

        clearInterval(betTimeTimerRef.current)
    }

    const handleUpdateState = (data: UpdateStateMessage) => {
        setState(data)

        setChartData(prev => {
            const prevX = prev[prev.length - 1][0]
            const prevY = prev[prev.length - 1][1]

            const stepCount = 7
            const x = data.data.updateStateData.second
            const y = data.data.updateStateData.odd

            const xStep = (x - prevX) / stepCount
            const yStep = (y - prevY) / stepCount

            const res: ChartDataType = []

            for (let i = 1; i <= stepCount; i++) {
               res.push([prevX + xStep * i, prevY + yStep * i])
            }

            return [...prev, ...res]
        })

        if (data.data.updateStateData.bets.length) {
            for (const bet of data.data.updateStateData.bets) {
                setLiveBets(prev =>
                    prev.map(liveBet => {
                        if (bet.id === liveBet.id) {
                            return {
                                ...liveBet,
                                winAmount: bet.winAmount,
                            }
                        } else {
                            return liveBet
                        }
                    })
                )

                setLiveCashouts(prev => [bet, ...prev])

                if (userIdRef.current && bet.userId === +userIdRef.current) {
                    winSound()
                    setWin(bet.winAmount)
                    setCurrentBet(null)
                }
            }
        }
    }

    const handleRoundFinished = (data: FinishedRoundMessage) => {
        crashSound()
        setIsCrashed(true)
        setCurrentBet(null)
    }

    const handleBet = (data: BetMessage) => {
        if (userIdRef.current && +userIdRef.current === data.userId) {
            setCurrentBet(data)
        }
        setLiveBets(prev => [data, ...prev])
    }

    useEffect(() => {
        const connect = async () => {
            let token: string | null = null

            if (oneTimeToken) {
                const { accessToken } = await Api().auth.login(oneTimeToken)
                token = accessToken
            }

            let wsUrl = process.env.REACT_APP_WS as string

            if (token) {
                wsUrl = wsUrl + '?access_token=' + token
            }

            const c = new HubConnectionBuilder()
                .withUrl(wsUrl)
                .withAutomaticReconnect()
                .build()
            setConnection(c)
        }
        connect()
    }, [])

    useEffect(() => {
        if (!connection) return

        const runConnection = async () => {
            connection.on('InitialStateResult', handleInitState)
            connection.on('BetTimeFinished', handleBetTimeFinished)
            connection.on('BetResult', handleBet)

            await connection.stop()
            await connection.start()
            let initStateDto: Object = { partnerId, gameName }

            if (mode === 'demo') {
                initStateDto = { ...initStateDto, isDemo: true }
            }

            await connection.invoke("InitialState", initStateDto )
        }
        runConnection()
    }, [connection])

    useEffect(() => {
        if (!connection) return
        connection.off('RoundStarted')
        connection.on('RoundStarted', handleRoundStart)
    }, [connection, amount, targetMultiplier, roundStartedData, nextBet])

    useEffect(() => {
        if (!connection) return
        connection.off('RoundFinished')
        connection.on('RoundFinished', handleRoundFinished)
        connection.off('UpdateState')
        connection.on('UpdateState', handleUpdateState)
    }, [connection, isMute])

    if (!Object.keys(initialState).length) {
        return (
            <div className="h-screen flex items-center justify-center">
                <img src={yo} alt="Yo" className="w-[200px]"/>
            </div>
        )
    }

    return (
        <div className={s.game}>
            <Chart
                data={chartData}
                roundStartedData={roundStartedData}
                betTime={betTime}
                isCrashed={isCrashed}
                win={win}
                history={history}
                currencyCode={initialState?.currencyCode}
                cashouts={liveCashouts}
            />
            <Control
                roundId={roundStartedData?.data.startedRound.roundId as number}
                amount={amount}
                setAmount={setAmount}
                targetMultiplier={targetMultiplier}
                setTargetMultiplier={setTargetMultiplier}
                isAutobet={isAutobet}
                setIsAutobet={setIsAutobet}
                numberOfRounds={numberOfRounds}
                setNumberOfRounds={setNumberOfRounds}
                state={state}
                betTime={betTime}
                onBet={onBet}
                onCashout={onCashout}
                currentBet={currentBet}
                win={win}
                nextBet={nextBet}
                setNextBet={setNextBet}
            />
            <Statistic
                liveBets={liveBets}
                liveCashouts={liveCashouts}
                stats={stats}
                gameName={gameName}
            />

            <Popup
                active={popupOpen.provablyFairness}
                setActive={value => openPopup('provablyFairness', value)}
            >
                {popupOpen.provablyFairness && (
                    <ProvablyFairnessModal
                        lastRound={
                            history.find(round => !!round.roundData)
                        }
                    />
                )}
            </Popup>

            <Popup
                active={popupOpen.rules}
                setActive={value => openPopup('rules', value)}
            >
                <RulesPopup />
            </Popup>
        </div>
    )
}

export default Game