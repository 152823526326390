import React, {FC} from 'react'
import s from './Switch.module.css'
import classNames from "classnames";

const Switch: FC<IProps> = ({ tabs, name, value, onChange, className }) => {
  return (
      <div className={classNames(s.switch, className)}>
        {tabs.map(tab => (
            <label key={tab} className={s.switch__item}>
              <input
                  type="radio"
                  name={name}
                  value={tab}
                  checked={value === tab}
                  onChange={e => onChange(e.target.value)}
              />
              {tab}
            </label>
        ))}
      </div>
  )
}

interface IProps {
  tabs: string[]
  name: string
  value: string
  onChange: (value: string) => void
  className?: string
}

export default Switch