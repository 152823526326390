import React, { FC, useContext, useMemo } from 'react'
import Table from '../../../../Table/Table'
import { Bet, BetMessage, CashoutMessage, CurrentBet } from '../../../../../types/State'
import { checkCurrencyCode } from '../../../../../utils/checkCurrencyCode'
import s from './LiveBets.module.css'
import InitialStateContext from '../../../../../context/InitialStateContext'
import { convertCurrency } from '../../../../../utils/convertCurrency'
import {useTranslation} from "../../../../../hooks/useTranslation";

const LiveBets: FC<IProps> = ({ liveBets, liveCashouts }) => {
    const { t } = useTranslation()
    const [initState] = useContext(InitialStateContext)

    const totalAmount = useMemo(
    () => liveBets?.reduce(
            (total, bet) => {
                if (bet.currencyCode === initState?.currencyCode) {
                    return total + bet.amount
                } else {
                    return total + bet.coinAmount * initState?.currencyRate
                }
            },
            0,
        ),
    [initState, liveBets])

    return (
        <>
            <div className={s.total}>
                <p>
                    <span />
                    {`${liveCashouts.length}/${liveBets.length}`}
                </p>
                <p>
                    {checkCurrencyCode(initState?.currencyCode)}{' '}
                    {totalAmount.toFixed(initState?.currencyPrecision)}
                </p>
            </div>
            <Table>
                <thead>
                <tr>
                    <td>{t('USERID')}</td>
                    <td>{t('BETAMOUNT')}</td>
                    <td>{t('PAYOUT')}</td>
                </tr>
                </thead>
                <tbody>
                {liveBets
                    ?.slice(0, 100)
                    .map(bet => (
                    <tr key={bet.id}>
                        <td>{bet.userId}</td>
                        <td>
                            {convertCurrency(
                                bet.coinAmount,
                                bet.amount,
                                bet.currencyCode,
                                initState,
                            )}
                        </td>
                        <td>
                            {convertCurrency(
                                bet.winCoinAmount,
                                bet.winAmount,
                                bet.currencyCode,
                                initState,
                            )}
                        </td>
                    </tr>
                ))}
                </tbody>
            </Table>
        </>
    )
}

export default LiveBets

interface IProps {
    liveBets: Array<CurrentBet | BetMessage>
    liveCashouts: Array<CashoutMessage | Bet>
}